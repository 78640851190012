<template>
  <c-box flexGrow="1">
    <c-breadcrumb
      fontSize="14px"
      lineHeight="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link
          color="brand.900"
          as="router-link"
          to="/admin/coaching-challenge"
        >
          Manajemen Diet Coaching & Challenge
        </c-breadcrumb-link>
      </c-breadcrumb-item>
      <c-breadcrumb-item>
        <c-breadcrumb-link isCurrent>
          {{ id ? "Ubah" : "Tambah" }} Diet Coaching & Challenge
        </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />
    <c-heading
      as="h3"
      font-family="Montserrat"
      font-weight="bold"
      font-size="24px"
      line-height="36px"
      mb="50px"
    >
      {{ id ? "Ubah" : "Tambah" }} Konten
    </c-heading>

    <form max-w="590px" v-chakra>
      <c-form-control mb="25px" is-required :isInvalid="type === ''">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Tipe Konten
        </c-form-label>
        <c-input-group size="md">
          <c-select
            :disabled="id"
            :variant="id ? 'filled' : ''"
            v-model="type"
            placeholder="Masukkan Tipe Konten"
            height="62px"
          >
            <option value="daily_coaching">Daily Coaching</option>
            <option value="coaching_course">Coaching Course</option>
            <option value="diet_challenge">Diet Challenge</option>
          </c-select>
        </c-input-group>
      </c-form-control>

      <c-form-control
        v-if="type !== 'coaching_course'"
        :isInvalid="type !== 'coaching_course' && showOnDay === ''"
        is-required
        mb="25px"
      >
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Tampilkan Hari Ke
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="number"
            min="0"
            error-border-color="red.300"
            placeholder="Masukkan Tampilkan Hari Ke"
            height="62px"
            v-model="showOnDay"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control :isInvalid="title === ''" is-required mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Judul Konten
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Judul Konten"
            height="62px"
            v-model="title"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control min-w="180px" w="180px" mr="40px" mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Preview Cover Program
        </c-form-label>
        <label
          for="img-preview"
          v-chakra="{
            bg: 'white',
            cursor: 'pointer',
            border: '1px solid #C4C4C4',
            boxSizing: 'border-box',
            borderRadius: '6px',
            w: '180px',
            h: '100%',
            minHeight: '180px',
            d: 'flex',
            p: '0',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
          }"
        >
          <c-image
            :src="getPreviewImage(imagePreview[0].url)"
            :h="
              !getPreviewImage(imagePreview[0].url).includes('icon-photo')
                ? '100%'
                : '94px'
            "
            alt="img program"
            mx="auto"
            border-radius="6px"
          />
        </label>
        <c-input
          style="display: none"
          type="file"
          id="img-preview"
          accept=".jpg, .jpeg, .png"
          error-border-color="red.300"
          placeholder="Masukkan Deskripsi Konten"
          height="62px"
          @change="onFileChange($event, 'preview')"
        />
      </c-form-control>

      <c-form-control :isInvalid="description === ''" is-required mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Deskripsi Konten
        </c-form-label>
        <wysiwyg
          v-model="description"
          placeholder="Masukkan Deskripsi Konten"
        />
        <!-- <textarea
          rows="8"
          placeholder="Masukkan Deskripsi Konten"
          v-chakra="{
            border: '1px solid #C4C4C4',
            padding: '18px',
            width: '100%',
            borderRadius: '6px',
            height: '151px',
            '&:focus, &:focus-visible': {
              outline: 'unset',
            },
          }"
          v-model="description"
        ></textarea> -->
      </c-form-control>

      <c-box
        v-for="(item, indexImg) in images"
        :key="'image' + indexImg"
        v-show="!item.preview"
        position="relative"
        mb="25px"
      >
        <c-box
          position="absolute"
          top="50%"
          right="-45px"
          transform="translateY(-50%)"
        >
          <c-button
            @click="removeMedia(item, indexImg)"
            ml="18px"
            min-width="auto"
            variant-color="red"
            variant="link"
          >
            <c-image
              :src="require('@/assets/icon-trash-line.svg')"
              alt="empty"
            />
          </c-button>
        </c-box>
        <c-flex align="baseline">
          <c-form-control min-w="180px" w="180px" mr="40px" :isInvalid="true">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Gambar Program
            </c-form-label>
            <label
              :for="'img-program-' + indexImg"
              v-chakra="{
                bg: 'white',
                cursor: 'pointer',
                border: '1px solid #C4C4C4',
                boxSizing: 'border-box',
                borderRadius: '6px',
                w: '180px',
                h: '100%',
                minHeight: '180px',
                d: 'flex',
                p: '0',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
              }"
            >
              <c-image
                :src="getPreviewImage(item.url)"
                :h="
                  !getPreviewImage(item.url).includes('icon-photo')
                    ? '100%'
                    : '94px'
                "
                alt="img program"
                mx="auto"
                border-radius="6px"
              />
            </label>
            <c-input
              style="display: none"
              type="file"
              :id="'img-program-' + indexImg"
              accept=".jpg, .jpeg, .png"
              error-border-color="red.300"
              placeholder="Masukkan Deskripsi Konten"
              height="62px"
              @change="onFileChange($event, indexImg)"
            />
          </c-form-control>
          <c-form-control w="100%" :isInvalid="true">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Caption Gambar
            </c-form-label>
            <textarea
              rows="8"
              placeholder="Masukkan Caption Gambar"
              v-chakra="{
                border: '1px solid #C4C4C4',
                padding: '18px',
                width: '100%',
                borderRadius: '6px',
                height: '180px',
                '&:focus, &:focus-visible': {
                  outline: 'unset',
                },
              }"
              v-model="item.caption"
            ></textarea>
          </c-form-control>
        </c-flex>
      </c-box>
      <c-button
        @click.prevent="addMediaImages"
        :isDisabled="isDisabledAddImg"
        variant-color="brand"
        variant="outline"
        borderRadius="60px"
        h="62px"
        w="100%"
        mb="25px"
        left-icon="add"
      >
        Tambah Gambar Lain
      </c-button>

      <c-box
        v-for="(item, index) in videos"
        :key="'video' + index"
        position="relative"
        mb="25px"
      >
        <c-flex align="baseline">
          <c-form-control min-w="180px" w="180px" mr="40px" :isInvalid="true">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Video Program
            </c-form-label>
            <label
              for="video-program"
              v-chakra="{
                bg: 'white',
                cursor: 'pointer',
                border: '1px solid #C4C4C4',
                boxSizing: 'border-box',
                borderRadius: '6px',
                w: '180px',
                h: '180px',
                d: 'flex',
                p: '0',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
              }"
            >
              <c-image
                v-if="getPreviewVideo(item.url).includes('icon-player-play')"
                :src="getPreviewVideo(item.url)"
                alt="video program"
                w="94px"
                mx="auto"
                border-radius="6px"
              />
              <video
                v-else
                v-chakra
                w="100%"
                h="100%"
                :src="media.url"
                controls
                autoplay="false"
                muted="muted"
              />
            </label>
            <c-input
              style="display: none"
              type="file"
              id="video-program"
              accept=".mp4"
              error-border-color="red.300"
              placeholder="Masukkan Deskripsi Konten"
              height="62px"
              @change="onFileChange($event, index)"
            />
          </c-form-control>
          <c-form-control w="100%" :isInvalid="true">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Caption Video
            </c-form-label>
            <textarea
              rows="8"
              placeholder="Masukkan Caption Video"
              v-chakra="{
                border: '1px solid #C4C4C4',
                padding: '18px',
                width: '100%',
                borderRadius: '6px',
                height: '180px',
                '&:focus, &:focus-visible': {
                  outline: 'unset',
                },
              }"
              v-model="item.caption"
            ></textarea>
          </c-form-control>
        </c-flex>
      </c-box>

      <c-form-control :isInvalid="category === ''" is-required mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Kategori
        </c-form-label>
        <c-input-group size="md">
          <c-select
            v-model="category"
            placeholder="Masukkan Kategori"
            height="62px"
          >
            <option
              v-for="item in categories"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </option>
          </c-select>
        </c-input-group>
      </c-form-control>

      <c-form-control mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Link YouTube
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Link YouTube"
            height="62px"
            v-model="linkYoutube"
          />
        </c-input-group>
      </c-form-control>

      <c-box v-if="type !== 'coaching_course'">
        <c-box
          v-for="(item, index) in files"
          :key="'file' + index"
          position="relative"
          mb="25px"
        >
          <c-box
            position="absolute"
            top="50%"
            right="-45px"
            transform="translateY(-50%)"
          >
            <c-button
              @click="removeMedia(item, index)"
              ml="18px"
              min-width="auto"
              variant-color="red"
              variant="link"
            >
              <c-image
                :src="require('@/assets/icon-trash-line.svg')"
                alt="empty"
              />
            </c-button>
          </c-box>
          <c-flex align="baseline">
            <c-form-control min-w="180px" w="180px" mr="40px" :isInvalid="true">
              <c-form-label
                fontSize="14px"
                color="#555555"
                fontWeigh="400"
                fontFamily="Roboto"
              >
                Upload File
              </c-form-label>
              <label
                :for="'file-program-' + index"
                v-chakra="{
                  bg: 'white',
                  cursor: 'pointer',
                  border: '1px solid #C4C4C4',
                  boxSizing: 'border-box',
                  borderRadius: '6px',
                  w: '180px',
                  h: '180px',
                  d: 'flex',
                  p: '0',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                }"
              >
                <c-image
                  :src="require('@/assets/icon-folder-upload.svg')"
                  alt="file program"
                  w="94px"
                  mx="auto"
                  border-radius="6px"
                />
              </label>
              <c-input
                style="display: none"
                type="file"
                :id="'file-program-' + index"
                accept=".pdf,.doc,.docx,application/msword"
                error-border-color="red.300"
                placeholder="Masukkan Deskripsi Konten"
                height="62px"
                @change="onFileChange($event, index)"
              />
            </c-form-control>
            <c-form-control alignSelf="center" w="100%" :isInvalid="true">
              <c-form-label
                fontSize="14px"
                color="gray.900"
                fontWeigh="normal"
                fontFamily="Roboto"
              >
                {{ getPreviewFile(item) }}
              </c-form-label>
            </c-form-control>
          </c-flex>
        </c-box>
        <c-button
          @click.prevent="addMediaFiles"
          :isDisabled="isDisabledAddFile"
          variant-color="brand"
          variant="outline"
          borderRadius="60px"
          h="62px"
          w="100%"
          mb="25px"
          left-icon="add"
        >
          Tambah File Lain
        </c-button>
      </c-box>

      <c-flex mt="28px" justify-content="space-between">
        <c-button
          variant="solid"
          variant-color="gray"
          border-radius="60px"
          w="50%"
          h="62px"
          mr="20px"
          font-size="18px"
          color="#555555"
          @click="onCancel"
        >
          Batal
        </c-button>
        <c-button
          variant="solid"
          variant-color="brand"
          border-radius="60px"
          w="50%"
          h="62px"
          font-size="18px"
          :isDisabled="isDisabledSubmit"
          @click="onSubmit"
        >
          Simpan
        </c-button>
      </c-flex>
    </form>
    <c-alert
      v-if="isUploading"
      position="fixed"
      top="5px"
      left="50%"
      transform="translateX(-50%)"
      borderRadius="3px"
      w="200px"
      status="info"
      fontSize="14px"
      zIndex="9999"
    >
      <c-alert-icon />
      Uploading...
    </c-alert>
  </c-box>
</template>

<script>
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";
import generalMixin from "@/utils/general-mixins";

export default {
  name: "AdminCoachingChallengeForm",
  components: {},
  mixins: [generalMixin],
  data() {
    return {
      type: this.$route.params.type ?? "daily_coaching",
      showOnDay: null,
      category: null,
      title: null,
      description: null,
      media: [],
      linkYoutube: null,

      imagePreview: [],
      images: [],
      videos: [],
      files: [],
      youtube: [],

      isUploading: false,
    };
  },
  computed: {
    ...mapState({
      categoryContent: (s) => s.admContentChallenge.category,
      categoryCoaching: (s) => s.admContentChallenge.categoryCoaching,
    }),
    ...mapGetters({
      axios: "axios",
      item: "admContentChallenge/item",
    }),
    id() {
      return this.$route.params.id;
    },
    categories() {
      if (this.type === "coaching_course") {
        return this.categoryCoaching.filter((it) => it.value);
      }
      return this.categoryContent.filter((it) => it.value);
    },
    isDisabledAddImg() {
      let emptyObj = this.images.findIndex((dt) => !dt.url && !dt.caption);
      if (this.images.length == 10 || emptyObj > -1) return true;
      return false;
    },
    isDisabledAddVideo() {
      let emptyObj = this.videos.findIndex((dt) => !dt.url && !dt.caption);
      if (emptyObj > -1) return true;
      return false;
    },
    isDisabledAddFile() {
      let emptyObj = this.files.findIndex((dt) => !dt.url && !dt.caption);
      if (this.files.length == 10 || emptyObj > -1) return true;
      return false;
    },
    isDisabledSubmit() {
      if (
        !this.category ||
        !this.type ||
        !this.title ||
        !this.description ||
        (this.type !== "coaching_course" && !this.showOnDay)
      )
        return true;
      return false;
    },
  },
  watch: {
    media: {
      immediate: true,
      handler() {
        if (this.media.length > 0) {
          this.imagePreview = this.media.filter(
            (it) => it.type === "image" && it.preview
          );
          this.images = this.media.filter((it) => it.type === "image");
          this.videos = this.media.filter((it) => it.type === "video");
          this.files = this.media.filter((it) => it.type === "file");
          this.youtube = this.media.filter((it) => it.type === "youtube");
          this.linkYoutube =
            this.youtube.length > 0 ? this.youtube[0].url : null;
        }

        if (this.imagePreview.length === 0) {
          this.imagePreview = [
            {
              type: "image",
              url: null,
              preview: true,
              caption: null,
              fileName: null,
              fileExtension: null,
              fileSize: null,
            },
          ];
        }

        if (this.images.length === 0) {
          this.images = [
            {
              type: "image",
              url: null,
              preview: false,
              caption: null,
              fileName: null,
              fileExtension: null,
              fileSize: null,
            },
          ];
        }
        if (this.videos.length === 0) {
          this.videos = [
            {
              type: "video",
              url: null,
              preview: false,
              caption: null,
              fileName: null,
              fileExtension: null,
              fileSize: null,
            },
          ];
        }
        if (this.files.length === 0) {
          this.files = [
            {
              type: "file",
              url: null,
              preview: false,
              caption: null,
              fileName: null,
              fileExtension: null,
              fileSize: null,
            },
          ];
        }
        if (this.youtube.length === 0) {
          this.youtube = [
            {
              type: "youtube",
              url: null,
              preview: false,
              caption: null,
              fileName: null,
              fileExtension: null,
              fileSize: null,
            },
          ];
        }
      },
    },
    item: {
      handler(val) {
        if (val) {
          this.type = val.type;
          this.showOnDay = val.showOnDay;
          this.category = val.category;
          this.title = val.title;
          this.description = val.description;
          this.media = val.media;
        }
      },
    },
    linkYoutube: {
      handler(val) {
        if (val) {
          this.youtube[0].url = val;
        }
      },
    },
    type: {
      immediate: true,
      handler(val) {
        if (val === "coaching_course") {
          this.files = [
            {
              type: "file",
              url: null,
              preview: false,
              caption: null,
              fileName: null,
              fileExtension: null,
              fileSize: null,
            },
          ];
        }
      },
    },
  },
  methods: {
    ...mapActions({
      createContentChallenge: "admContentChallenge/createContentChallengeAdmin",
      detailContentChallenge: "admContentChallenge/detailContentChallengeAdmin",
      updateContentChallenge: "admContentChallenge/updateContentChallengeAdmin",
    }),
    addMediaImages() {
      if (this.images.length == 10) return;
      this.images.push({
        type: "image",
        url: null,
        preview: false,
        caption: null,
        fileName: null,
        fileExtension: null,
        fileSize: null,
      });
    },
    addMediaFiles() {
      this.files.push({
        type: "file",
        url: null,
        preview: false,
        caption: null,
        fileName: null,
        fileExtension: null,
        fileSize: null,
      });
    },
    getPreviewImage(url) {
      if (url) {
        return url;
      }
      return require("@/assets/icon-photo.svg");
    },
    getPreviewVideo(url) {
      if (url) {
        return url;
      }
      return require("@/assets/icon-player-play.svg");
    },
    getPreviewFile(item) {
      if (item.fileName) {
        return item.fileName;
      }
      return "File maksimal 3 MB";
    },
    onFileChange(input, index) {
      if (this.isUploading) return;
      if (input.target.files && input.target.files[0]) {
        let file = input.target.files[0];
        this.isUploading = true;
        this.$toast({
          description: `Uploading...`,
          status: "info",
          duration: null,
          position: "top",
          variant: "subtle",
        });
        if (file.type.includes("image") && file.size > 2097152) {
          this.isUploading = false;
          return this.$toast({
            title: "Failed",
            description: `This file can't be uploaded, because it (${this.formatBytes(
              file.size
            )}) exceeds the maximum file size (2 MB).`,
            status: "error",
            duration: 10000,
            position: "bottom-right",
            variant: "subtle",
          });
        }
        // Upload avatar to API
        let formData = new FormData();
        formData.append("file", input.target.files[0]);
        for (var pair of formData.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }

        this.axios
          .post(`/v1/admin/content-and-challenge/upload`, formData)
          .then((it) => it.data)
          .then((it) => {
            this.isUploading = false;
            if (index === "preview" && file.type.includes("image")) {
              this.imagePreview[0].type = "image";
              this.imagePreview[0].url = it.data.url;
              this.imagePreview[0].fileName = it.meta.fileName;
              this.imagePreview[0].fileExtension = it.meta.fileExtension;
              this.imagePreview[0].fileSize = it.meta.fileSize;
              this.imagePreview[0].preview = true;
              this.media.push(this.imagePreview[0])
            } else if (file.type.includes("image")) {
              this.images[index].type = "image";
              this.images[index].url = it.data.url;
              this.images[index].fileName = it.meta.fileName;
              this.images[index].fileExtension = it.meta.fileExtension;
              this.images[index].fileSize = it.meta.fileSize;
              this.media.push(this.images[index])
            } else if (file.type.includes("video")) {
              this.videos[index].type = "video";
              this.videos[index].url = it.data.url;
              this.videos[index].fileName = it.meta.fileName;
              this.videos[index].fileExtension = it.meta.fileExtension;
              this.videos[index].fileSize = it.meta.fileSize;
              this.media.push(this.videos[index])
            } else {
              this.files[index].type = "file";
              this.files[index].url = it.data.url;
              this.files[index].fileName = it.meta.fileName;
              this.files[index].fileExtension = it.meta.fileExtension;
              this.files[index].fileSize = it.meta.fileSize;
              this.media.push(this.files[index])
            }
          })
          .catch(() => {
            this.$toast({
              title: "Failed",
              description: "Ops! Something when wrong.",
              status: "error",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
          });
      }
    },
    onSubmit() {
      let filteredImages = this.images.filter((dt) => !dt.preview && dt.url)
      let _images = this.imagePreview.concat(filteredImages)
      let _videos = this.videos.filter((it) => it.url);
      let _files = this.files.filter((it) => it.url);
      _files = _.sortBy(_files, "url");
      let _youtube = this.youtube.filter((it) => it.url);
      let _media = _images.concat(_videos, _files, _youtube);
      if (this.type === "coaching_course") {
        _media = _images.concat(_videos, _youtube);
      }
      let params = {
        type: this.type,
        showOnDay: this.showOnDay,
        category: this.category,
        title: this.title,
        description: this.description,
        media: _media,
      };
      if (this.id) {
        params.id = this.id;
        return this.updateContentChallenge(params)
          .then(() => {
            this.$toast({
              title: "Success",
              description: "Update content is successfully",
              status: "success",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
            this.$router.push({
              name: "admin.coaching-challenge.detail",
              params: { id: this.id },
            });
          })
          .catch((err) => {
            this.$toast({
              title: "Failed",
              description: err.data.message ?? "Ops! Something when wrong.",
              status: "error",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
          });
      }
      return this.createContentChallenge(params)
        .then(() => {
          this.$toast({
            title: "Success",
            description: "Create content is successfully",
            status: "success",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          });
          this.$router.push({
            name: "admin.coaching-challenge",
            query: { type: this.type },
          });
        })
        .catch((err) => {
          this.$toast({
            title: "Failed",
            description: err.data.message ?? "Ops! Something when wrong.",
            status: "error",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          });
        });
    },
    removeMedia(item, index) {
      this.imagePreview = this.imagePreview.filter((it) => it.url !== item.url)
      this.media = this.media.filter((it) => it.url !== item.url)
      this.images = this.images.filter((it) => it.url !== item.url)
      this.videos = this.videos.filter((it) => it.url !== item.url)
      this.files = this.files.filter((it) => it.url !== item.url)
    },
    onCancel() {
      if (this.id) {
        this.$router.push({
          name: "admin.coaching-challenge.detail",
          params: { id: this.id },
        });
      } else {
        this.$router.push({
          name: "admin.coaching-challenge",
          query: { type: this.type },
        });
      }
    },
  },
  async mounted() {
    if (!this.id) return;
    this.detailContentChallenge(this.id);
  },
};
</script>
